<template>
	<div id="paxExpress">
		<header>
			<b-navbar toggleable="lg">
			    <b-navbar-brand href="#"><groomy-logo/></b-navbar-brand>
			    <!-- <b-navbar-toggle target="nav-collapse"><font-awesome-icon :icon="['far', 'stream']" /> {{ $t('pax.menu') }}</b-navbar-toggle>
			    <b-collapse id="nav-collapse" is-nav>
			        <b-navbar-nav>
			            <b-nav-item href="https://www.gfeweb.com/?open_menu=true" target="_blank"><font-awesome-icon :icon="['far', 'stream']" />  {{ $t('pax.menu') }}</b-nav-item>
			        </b-navbar-nav>
			    </b-collapse> -->
			    <span class="nav-item navbar-nav"><a href="https://www.gfeweb.com/?open_menu=true" class="nav-link px-3" target="_blank"><font-awesome-icon :icon="['far', 'stream']" />  {{ $t('pax.menu') }}</a></span>
			</b-navbar>
		</header>

		<div class="tabs mb-3 d-lg-none">
			<ul class="nav nav-tabs nav-justified">
				<li class="nav-item"><a class="nav-link" :class="{'active' : step < 3, }" @click="goToHome"><b><font-awesome-icon :icon="['fas', 'home']"/></b><span>{{ $t('pax.accueil') }}</span></a></li>
				<li class="nav-item"><a class="nav-link" :class="{'disabled' : step < 3,'active' : step === 3, }" @click="goToStep(3)"><b>1</b><span>{{ $t('pax.etalons') }}</span></a></li>
				<li class="nav-item"><a class="nav-link" :class="{'disabled' : step < 4, 'active': step === 4}" @click="goToStep(4)"><b>2</b><span>{{ $t('pax.juments') }}</span></a></li>
				<li class="nav-item"><a class="nav-link" :class="{'disabled' : step < 5, 'active': step === 5}" @click="goToStep(5)"><b>3</b><span>{{ $t('pax.comparaison') }}</span></a></li>
			</ul>
		</div>
		
		<div class="container-fluid">
			<div class="row justify-content-center">
				<ChoixLangue v-show="step === 1" @langue_choosed="goToCountry"/>
				<ChoixCountry v-show="step === 2" @country_choosed="goToPax" :pax_express="true"/>
				<template v-if="step > 2">
					<div class="col bstallion" v-show="step === 3 || ((isLg || isXl) && step === 4)">
						<Stallions ref="stallions" :stallions_selected.sync="stallions_selected" :step_label.sync="step_label" :pax_express="true"></Stallions>
					</div>
					<div class="col-12 col-lg-4 bmare" v-show="step === 4 || ((isLg || isXl) && step === 3)">
						<Mare :force_refresh="force_refresh" :criteres.sync="criteres" :pax_express="true">></Mare>
						<ResultCompatibiliteH v-if="(!isLg && !isXl)" :displayCompatibilite.sync="displayCompatibilite" :stallions="stallions_selected" :criteres="criteres" :step_label.sync="step_label" :stallions_comparaison.sync="stallions_comparaison" :pax_express="true"></ResultCompatibiliteH>
					</div>
					<div class="col-4" v-if="(isLg || isXl) && step === 4"> <!-- && criteres_filled.length >= 3"-->
						<ResultCompatibiliteHFull :displayCompatibilite.sync="displayCompatibilite" :stallions="stallions_selected" :criteres="criteres" :step_label.sync="step_label" :stallions_comparaison.sync="stallions_comparaison" :pax_express="true"></ResultCompatibiliteHFull>
					</div>
					<div class="col-12" v-show="step === 5">
						<ResultCompatibiliteV :stallions="stallions_selected" :step_label.sync="step_label" :criteres="criteres" :pax_express="true"></ResultCompatibiliteV>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>


<script type="text/javascript">
    import Constants from 'Constants'
	import UserMixin from '@/mixins/User.js'
	import Navigation from '@/mixins/Navigation.js'
	import PaxMixin from '@/mixins/Pax.js'
	import Config from '@/mixins/Config.js'
	import Window from '@/mixins/Window.js'
	import _uniqueId from 'lodash/uniqueId'

	export default {
		name: "PaxExpress",
		mixins: [UserMixin, Navigation, PaxMixin, Config, Window],
		data () {
			return {
				stallions_selected: [],
				criteres: [],
				displayCompatibilite: false,
				force_refresh: false,
				step: 1,
				step_label: "",
				stallions_comparaison: []
			}
        },
		created() {
            this.init_component()
		},
		methods: {
			async init_component() {
				await this.loadTranslation()
				this.stallions_selected = []
				this.displayCompatibilite = false
				this.criteres = []
				// au reload permet de mettre à jour les critères à l'étape pax
				this.force_refresh = _uniqueId()

				const lang = this.getConfig('lang')
				const country = this.getConfig('country')
				if(lang && country) {
					this.step = 3
				}
				else if(lang && !country) {
					this.step = 2
				}
				else {
					this.step = 1
				}
			},

			async goToStep(step_to_go) {
				if(step_to_go < this.step) {
					this.step_label = ""
					this.step = step_to_go
				}
			},

			goToCountry() {
				this.step = 2
			},

			goToPax() {
				this.step = 3
			},

			goToHome() {
				this.rmConfigByType('lang')
				this.rmConfigByType('country')
				this.init_component()
			},
		},
		computed: {
			lang() {
				return this.getConfig('lang') ?? false
			},
			country() {
				return this.getConfig('country') ?? false
			},
			criteres_filled() {
				return this.criteres.filter(critere => critere.slider)
			}
		},
		watch: {
			step_label(val) {
				if(val == "stallions_selected" || val == "mare_list") {
					this.step = 4
				}
				else if(val == "country") {
					this.step = 2
				}
				else if(val == "comparaison_V") {
					this.step = 5
				}
			}
		},
		components: {
			Stallions : () => import('@/components/Pax/Stallions'),
			Mare : () => import('@/components/Pax/Mare'),
			ResultCompatibiliteH : () => import('@/components/Pax/ResultCompatibiliteH'),
			ResultCompatibiliteHFull : () => import('@/components/Pax/ResultCompatibiliteHFull'),
			ResultCompatibiliteV : () => import('@/components/Pax/ResultCompatibiliteV'),
			ChoixLangue: () => import('@/components/Pax/ChoixLangue'),
			ChoixCountry: () => import('@/components/Pax/ChoixCountry')
		}
	};
</script>
